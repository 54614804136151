import React from 'react';
import {
  ComponentPreview,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
} from 'docComponents';
import { ItemTitle } from 'hudl-uniform-ui-components';
import itemtitlePreviewData from '../../../../data/previews/itemtitle.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Item Title"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Type" tierThree="Item Title" />

      <PageNavigation>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>Style</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Microcopy</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Size">
        <Paragraph>As usual, it all depends on context.</Paragraph>
        <ComponentPreview
          name="ItemTitleSize"
          layout="default"
          previewData={itemtitlePreviewData.levels}>
          <ItemTitle>Email Address</ItemTitle>
        </ComponentPreview>
      </Section>

      <Section title="Style">
        <Paragraph>
          The all-caps variation of item title makes every letter uppercase,
          creating an extra layer of visual density not provided by the standard
          item title.
        </Paragraph>
        <ComponentPreview
          name="ItemTitleStyle"
          layout="default"
          previewData={itemtitlePreviewData.types}>
          <ItemTitle>Order Number</ItemTitle>
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <List>
          <li>When referencing Hudl, always use the default style.</li>
          <li>Limit the usage of all-caps style to one-word phrases.</li>
        </List>
      </Section>

      <Section title="Microcopy">
        <Paragraph>
          Use the{' '}
          <Link href="/words/content-elements/labels-non-label-text">
            UI label guidelines
          </Link>{' '}
          when typesetting item titles.
        </Paragraph>
      </Section>

      <Section title="Platform">
        <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple" />
          <PlatformTableRow platform="android" />
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
